import Options from '@src/views/game/components/Step/components/Stepper'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import styles from '../styles.module.scss'

const MessageInputPopup = observer(({ stepContent, stepController }: any) => {
  const [message, setMessage] = useState('')
  const [correct, setCorrect] = useState(false)
  useEffect(() => {
    if (stepContent.validation && stepContent.answer) {
      // make answers non-case sensitive
      if (stepContent.answer.toLocaleLowerCase() === message.toLocaleLowerCase()) {
        setCorrect(true)
      } else setCorrect(false)
    } else {
      setCorrect(true)
    }
  }, [message])

  return (
    <Modal show={true} className={'game-popup'} backdropClassName={'chat-modal-backdrop'}>
      <Modal.Body className={styles['popup-body']}>
        <div className={styles['popup-question']}>
          <h5>{stepContent.title}</h5>
        </div>
        <div className={'d-block'}>
          <label className={'textfield ' + styles.inputContainer}>
            <div>
              <input
                id="message-input"
                type="text"
                className={styles['popup-input']}
                value={message}
                onChange={(e: any) => setMessage(e.target.value)}
              />
            </div>
          </label>
          <Options stepController={stepController} extra={{ message }} setExtra={setMessage} correct={correct} />
        </div>
      </Modal.Body>
    </Modal>
  )
})

export default MessageInputPopup
