async function getLocation() {
  if (navigator.geolocation) {
    return await new Promise(function(resolve, reject) {
      navigator.geolocation.getCurrentPosition((position)=>{
      resolve({
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      })
    })
    })
  } else {
    return {
      lat: null,
      lng: null,
    }
  }
}
export const activate = (target: string) => {
  return fetch(`${process.env.REACT_APP_BACKEND_URL}/artifacts/games/activate`, {
    method: 'POST',
    headers: {
      authorization: sessionStorage.getItem('firebaseToken'),
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      target
    })
  } as any).then((res) => res.json())
}
export const generate = (missionId: string, locale: string) => {
  return fetch(`${process.env.REACT_APP_BACKEND_URL}/artifacts/games/generate`, {
    method: 'POST',
    headers: {
      authorization: sessionStorage.getItem('firebaseToken'),
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      missionId, locale
    })
  } as any).then((res) => res.json())
}
export const reset = (groupId: string) => {
  return fetch(`${process.env.REACT_APP_BACKEND_URL}/artifacts/games/reset`, {
    method: 'POST',
    headers: {
      authorization: sessionStorage.getItem('firebaseToken'),
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      groupId
    })
  } as any).then((res) => res.json())
}
export const stepper = (groupId: string) => {
  const ts = Date.now()
  return fetch(`${process.env.REACT_APP_BACKEND_URL}/artifacts/games/stepper/${ts}?ts=${ts}`, {
    method: 'POST',
    headers: {
      authorization: sessionStorage.getItem('firebaseToken'),
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      groupId,
      _ts: ts,
    })
  } as any).then((res) => res.json())
}
export const updateStepper = async (groupId: string, optionId: string, extra = {}) => {
  const ts = Date.now()
  // const geo = await getLocation()
  return fetch(`${process.env.REACT_APP_BACKEND_URL}/artifacts/games/next-step/${ts}?ts=${ts}`, {
    method: 'POST',
    headers: {
      authorization: sessionStorage.getItem('firebaseToken'),
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      optionId, groupId, ...extra, geo: {lat: 0, lng: 0},
      _ts: ts,
    })
  } as any).then((res) => res)
}

export const getFinale = (groupId: string) => {
  return fetch(`${process.env.REACT_APP_BACKEND_URL}/artifacts/games/finale`, {
    method: 'POST',
    headers: {
      authorization: sessionStorage.getItem('firebaseToken'),
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      groupId,
    })
  } as any).then((res) => res.json())
}

export const postCoupon = (webhook:string, email: string, stepContent: any) => {
  return fetch(webhook, {
    method: 'POST',
    // headers: {
    //   authorization: sessionStorage.getItem('firebaseToken'),
    //   'Content-Type': 'application/json',
    // },
    body: JSON.stringify({
      email,
      ...stepContent
    })
  } as any).then((res) => res.json())
}
